import React from 'react';
import './Features.scss';
import { FcAlarmClock, FcSearch, FcCalendar, FcBriefcase } from 'react-icons/fc';
import { useTranslation } from 'react-i18next';

function Features() {
    const { t } = useTranslation();

    return (
        <div className="features">
            <div className="features-container">
                <ul>
                    <li>
                    <div className="icon-container">
                        <FcSearch className="features-icon" />
                    </div>
                    <p className="features-title">{t('features.discover')}</p>
                    </li>
                    <li>
                    <div className="icon-container">
                        <FcAlarmClock className="features-icon" />
                    </div>
                    <p className="features-title">{t('features.notify')}</p>
                    </li>
                    <li>
                    <div className="icon-container">
                        <FcCalendar className="features-icon" />
                    </div>
                    <p className="features-title">{t('features.calendar')}</p>
                    </li>
                    <li>
                    <div className="icon-container">
                        <FcBriefcase className="features-icon" />
                    </div>
                    <p className="features-title">{t('features.manage')}</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Features
