import React, { ChangeEvent, useCallback, useState } from 'react';
import logo from './logo.png';
import './App.scss';
import Home from './screens/Home';
import Features from './screens/Features';
import Timeline from './components/Timeline';
import { FaDiscord } from 'react-icons/fa';
import { AiOutlineMail, AiOutlineTwitter } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import Faq from './screens/Faq';
import i18n from './i18n';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import TermsOfUse from './screens/TermsOfUse';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ResetPassword from './screens/ResetPassword';

const options = [
  { value: 'fr-FR', label: 'Français' },
  { value: 'en-US', label: 'English' }
];

function App() {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language.includes('fr') ? options[0].value : options[1].value);

  const changeLanguage = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      i18n.changeLanguage(event.target.value);
      setSelectedLanguage(event.target.value);
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/terms-of-use/:lang">
          <TermsOfUse />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/">
          <div className="App">
            <div className="bg" />
            <div className="bg-path" />
            <header className="header">
              <img className="logo" alt="agame logo" src={logo} />
              <div className="right-side">
                <select className="select" value={selectedLanguage} onChange={changeLanguage}>
                  {options.map((option: {value: string, label: string}, index: number) => {
                    return <option key={index} value={option.value}>{option.label}</option>;
                  })}
                </select>
                <FaDiscord className="discord" onClick={() => window.open('https://discord.gg/kHfx56Rp3J', '_blank')} />
              </div>
            </header>
            <Home />
            <Features />
            <div className="timeline">
              <p className="title">{t('timeline.roadmap')}</p>
              <h1 style={{color: 'white'}}>{t('timeline.title')}</h1>
              <Timeline />
            </div>
            <Faq />
            <div className="contact">
              <div className="contact-card">
                <p className="title">{t('contact.title')}</p>
                <a className="contact-line" href="mailto:hello@agame.shop"><AiOutlineMail className="mail-icon" />&nbsp;hello@agame.shop</a>
                <a className="contact-line" href="https://twitter.com/agame_app"><AiOutlineTwitter className="mail-icon" />&nbsp;@agame_app</a>
              </div>
            </div>
            <p className="metadata">{t('footer.metadata')}</p>
            <p>{t('footer.copyright')}</p>
            <footer className="footer" />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
