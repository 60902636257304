const fr = {
    translation: {
        home: {
            typing_one: 'PASSE AU NIVEAU SUPERIEUR.',
            typing_two: 'GAMING. ACTUALITES. COMMUNAUTE.',
            main_desc: 'Toutes les informations jeux vidéos réunies en un seul et même endroit.',
            author: 'Par Yannick Mougin',
            preregister: 'Préinscription',
        },
        features: {
            discover: 'Découvrir des nouveaux jeux',
            notify: 'Recevoir des notifications aux sorties de jeux',
            calendar: 'Profiter d\'un vrai calendrier des sorties',
            manage: 'Gérer vos collections de jeux'
        },
        timeline: {
            roadmap: 'Roadmap',
            title: 'Quelle est la suite ?',
            content_one: 'Sortie du site de présentation',
            content_two: 'Lancement officiel de l\'application Android',
            content_three: 'Lancement officiel de l\'application iOS',
        },
        faq: {
            faq: 'FAQ',
            title: 'Questions fréquentes',
            questions: {
                free_q: 'L\'application est-elle gratuite ?',
                free_a: 'Oui complètement ! L\'application contient des publicités qui lui permettent d\'être rémunérée et de se développer. Tu peux également devenir membre premium et ainsi profiter de l\'application sans aucune pub.',
                aim_q: 'Quel est l\'objectif de l\'application ?',
                aim_a: 'L\'objectif de l\'application est de mettre à porté de main de chaque joueur toutes les informations dont il a besoin pour profiter au maximum de sa passion.',
                store_q: 'Sur quelle plateforme l\'application est-elle disponible ?',
                store_a: 'L\'application est disponible sur iOS (Apple) et Android. Tu peux la télécharger via les boutons en haut de page.',
                language_q: 'Dans quelle langue l\'application est-elle disponible ?',
                language_a: 'Pour le moment, elle est disponible en Français et en Anglais. Si tu souhaites voir apparaître une autre langue n\'hésite pas à nous contacter.'
            }
        },
        reset_password: {
            title: 'Récupération de mot de passe',
            new_password: "Nouveau mot de passe",
            confirm: "Confirmer",
            success: "Ton mot de passe a été réinitialisé ! Tu peux retourner sur l'application AGAME pour te connecter",
            errors: {
                rules: "Ton mot de passe doit contenir au minimum 8 caractères dont une lettre majuscule, une minuscule et un chiffre",
                bad_infos: "Votre code de réinitialisation est invalide ou expiré."
            }
        },
        contact: {
            title: 'Contact'
        },
        footer: {
            metadata: 'Games metadata is powered by IGDB.com',
            copyright: 'Yannick Mougin 2021 © Tous droits réservés'
        }
    }
};

export default fr;