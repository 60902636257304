import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyAFQtDcnpbkVvfauUpySDqJjUAYmRBgttw",
  authDomain: "agame-9e708.firebaseapp.com",
  projectId: "agame-9e708",
  storageBucket: "agame-9e708.appspot.com",
  messagingSenderId: "133916947248",
  appId: "1:133916947248:web:df24ef4dce8ce4abf9cef5"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();
export { auth };