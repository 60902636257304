const en = {
    translation: {
        home: {
            typing_one: 'LEVEL UP YOUR GAMES.',
            typing_two: 'NEWS. GAMING. COMMUNITY.',
            main_desc: 'Everything you need to know about gaming in only one place.',
            author: 'By Yannick Mougin',
            preregister: 'Preregister',
        },
        features: {
            discover: 'Discover new games',
            notify: 'Get notify about upcoming games',
            calendar: 'Enjoy our release calendar',
            manage: 'Manage your games library'
        },
        timeline: {
            roadmap: 'Roadmap',
            title: 'So, what\'s coming ?',
            content_one: 'Showcase website launching',
            content_two: 'Android app launching',
            content_three: 'iOS app launching',
        },
        faq: {
            faq: 'FAQ',
            title: 'Frequently asked questions',
            questions: {
                free_q: 'Is the app free ?',
                free_a: 'Yes absolutely ! The application contains advertisements which allow it to be paid and to grow. Users can also become support member and thus enjoy the application without any ads.',
                aim_q: 'What is the main purpose of Agame ?',
                aim_a: 'The target is to put at a fingertips all the informations a gamer needs to enjoy at the fullest his passion.',
                store_q: 'Where can i find the app ?',
                store_a: 'The app is available both on iOS (Apple) and Android. You can use the dedicated buttons above to download it.',
                language_q: 'Which language is available in the app ?',
                language_a: 'At the moment, it is available in French and English. If you want to see another language appear, do not hesitate to contact us.'
            }
        },
        reset_password: {
            title: 'Reset password',
            new_password: "New password",
            confirm: "Confirm",
            success: "Success ! You can now use your new password to connect to the AGAME app",
            errors: {
                rules: "Your password must contain at least 8 characters with one capital letter, one lower case and one number",
                bad_infos: "The reset code provided is invalid or out-of-date"
            }
        },
        contact: {
            title: 'Contact'
        },
        footer: {
            metadata: 'Games metadata is powered by IGDB.com',
            copyright: 'Yannick Mougin 2021 © all rights reserved'
        }
    }
};

export default en;