import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useQueryParam from '../hooks/useQueryParam';
import './ResetPassword.scss';
import { auth } from '../firebase';
import i18n from '../i18n';

function ResetPassword() {
    const [password, setPassword] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showReset, setShowReset] = useState<boolean>();
    const oobCode = useQueryParam().get("oobCode");
    const lang = useQueryParam().get("lang") || 'en';
    const { t } = useTranslation();

    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_$&+,:;=?@#|'<>.\-^*()%!]{8,}$/);
    const confirmLabel = t('reset_password.confirm');

    useEffect(() => {
        if (oobCode) {
            auth.verifyPasswordResetCode(oobCode)
            .then(() => setShowReset(true))
            .catch((err) => {
                setShowReset(false)
            })
        }
    }, [oobCode]);

    useEffect(() => {
        if (lang) {
            const selectedLanguage = lang && lang.includes('fr') ? 'fr-FR' : 'en-US';
            i18n.changeLanguage(selectedLanguage);
        }
    }, [lang]);

    const handleChange = (event: any) => {
        setPassword(event.target.value.trim());
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (password && regex.test(password)) {
            setIsValid(true);
            auth.confirmPasswordReset(oobCode!, password)
            .then(() => setIsSuccess(true))
            .catch(() => setIsSuccess(false))
        } else {
            setIsValid(false);
        }
    }

    const resetForm = () => {
        return  isSuccess ?  <p className="white">{t('reset_password.success')}</p>
        : (
            <div className="input-container">
                {!isValid && <p style={{color: 'tomato'}}>{t('reset_password.errors.rules')}</p>}
                <form onSubmit={handleSubmit}>
                    <label className="white">
                    {t('reset_password.new_password')}
                    </label>
                    <br />
                    <input type="password" value={password} onChange={handleChange} />

                    <input type="submit" value={confirmLabel} />
                </form>
            </div>
        )
    }

    return (
        <div className="container">
            <img style={{height: 120}} src="https://api.agame.shop/images/logo.png" alt="agame logo" loading="lazy"/>  
            <h2 className="white">{t('reset_password.title')}</h2>
            {showReset ?
                resetForm()
                :
                <p className="white">{t('reset_password.errors.bad_infos')}</p>
            }

        </div>
    )
}

export default ResetPassword;