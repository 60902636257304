import React from 'react';
import { useParams } from 'react-router-dom';

const frTerms: string = `ARTICLE 1 – OBJET DES CONDITIONS GENERALES D’UTILISATION

Les présentes conditions générales d’utilisation (ci-après « CGU ») ont pour objet de définir les règles d’utilisation de l’application mobile « AGAME » (ci-après « l’Application ») éditée par Yannick Mougin.
Président : hello@agame.shop.
En installant l’Application sur votre terminal et/ou en accédant à l’Application, vous acceptez sans réserve l’intégralité des présentes CGU et vous engagez à respecter les obligations à votre charge. 
Dans le cas où vous n’accepteriez pas les CGU ou auriez des réserves, merci de ne pas utiliser l’Application.
Les CGU expriment l'intégralité de l'accord entre vous et AGAME applicable à votre utilisation de l’Application. 
AGAME se réserve le droit de modifier ces CGU en les mettant à jour à tout moment. 
La version des CGU qui est applicable entre vous et AGAME est celle en vigueur au moment de votre connexion et de votre utilisation de l’Application. 
Nous vous conseillons donc de consulter cette page régulièrement afin de prendre connaissance des CGU en vigueur lorsque vous utilisez l’Application.

ARTICLE 2 – OBJET ET FONCTIONNALITES DE L’APPLICATION

L’Application vous permet de :
- Gérez vos jeux vidéos
- Découvrir de nouveaux jeux vidéos
- Consulter le calendrier des sorties de jeux vidéos
- Comparer le prix des jeux chez plusieurs revendeurs

Ces fonctionnalités peuvent être enrichies ou modifiées à tout moment par AGAME sans que sa responsabilité ne puisse être engagée à ce titre.

ARTICLE 3 - ACCES A L’APPLICATION

Pour accéder à l’Application, vous devez posséder un terminal mobile et un accès au réseau Internet.
L’Application est téléchargeable gratuitement depuis les plateformes « Apple Store » et « Google Play Store » sur les terminaux mobiles suivants :
- téléphone mobile Iphone® d’Apple® disposant du système d’exploitation iOS sous iOS 10.0 minimum
- téléphone mobile disposant du système d’exploitation Android® Lollipop (API 21) minimum.
La version du logiciel de l’Application peut être mise à jour de temps à autre pour ajouter de nouvelles fonctions et de nouveaux services.
Une fois l’Application installée sur votre terminal, il vous suffit de vous connecter en démarrant l'application.

ARTICLE 4 – LICENCE D’UTILISATION

AGAME vous concède un droit personnel d’utilisation de l’Application et de son contenu.
Ce droit est accordé à titre non exclusif, révocable, non cessible, non transférable,
mondial et gratuit et pour la seule utilisation de l’Application, à l’exclusion de toute autre finalité.
Il est strictement interdit d’accéder et/ou d’utiliser et/ou de tenter d’accéder ou d’utiliser les codes sources ou objets de l’Application.
Vous n’acquérez aucun droit de propriété intellectuelle sur l’Application ou son contenu, ni aucun autre droit que ceux conférés par les présentes CGU.

ARTICLE 5 - DONNÉES PERSONNELLES

Nous n'utilisons aucunes de vos données personnelles.
Seul votre email est requise pour créér un compte. Cet email est utilisé uniquement pour la communication et la récupération de mot de passe.
Vos droits d’accès, de rectification et de suppression peuvent être exercés en nous envoyant un mail à l’adresse hello@agame.shop

ARTICLE 6 - PROPRIÉTÉ INTELLECTUELLE

L'ensemble du contenu de l’Application relève de la législation française, communautaire et internationale sur le droit d'auteur et la propriété intellectuelle.
Tous les droits de reproduction et de représentation afférents à l’Application sont réservés par AGAME, y compris toutes représentations graphiques, iconographiques et
photographiques, ce quel que soit le territoire de protection et que ces droits aient fait l'objet d'un dépôt ou non.
La reproduction et/ou représentation de tout ou partie de l’Application, quel que soit le
support, en ce compris tous noms commerciaux, marques, logos, noms de domaine et autres
signes distinctifs, est formellement interdite et constituerait une contrefaçon sanctionnée par
le code de la propriété intellectuelle.
Les mots AGAME et tous les logos sont des marques déposées.

ARTICLE 7 – DISPONIBILITE DE L’APPLICATION

L’Application est accessible en ligne 7 jours sur 7 et 24 heures sur 24. Toutefois, compte tenu
de la complexité du réseau internet et de l'afflux, à certaines heures, des utilisateurs
d'internet, AGAME n'offre aucune garantie de continuité du service et ne pourra voir
sa responsabilité engagée en cas d'impossibilité temporaire d'accéder en tout ou partie à
l’Application. Aucun droit à indemnisation n’est accordé au titre du présent alinéa.
AGAME se réserve le droit de cesser, sans préavis, sans indemnité et à son entière
discrétion, à titre définitif ou provisoire, de fournir tout ou partie du Service ou des
fonctionnalités de l'application.

ARTICLE 8 - LIMITATION DE RESPONSABILITE – ABSENCE DE GARANTIE

AGAME s'engage à mettre en œuvre tous les moyens nécessaires afin d'assurer au
mieux l’accès à l’Application.
Vous êtes seul responsable de votre identifiant. Vous vous engagez à les garder confidentiels
et à ne pas les transmettre à des tiers. Si vous avez connaissance d'une utilisation non
autorisée de votre compte, il vous appartient d'en informer immédiatement AGAME
en écrivant à l'adresse suivante : hello@agame.shop
De manière générale, vous acceptez et reconnaissez que votre utilisation de l’Application, y
compris les informations que vous diffusez, est faite sous votre unique et entière
responsabilité.
En utilisant l’Application, vous vous engagez à ne pas agir de manière dommageable ou ayant
pour effet de causer un préjudice à l’image, aux intérêts ou aux droits de AGAME,
d’endommager ou de rendre inopérante l’Application.
AGAME ne saurait être tenue pour responsable et ne saurait être tenue de vous
indemniser du préjudice direct ou indirect qui résulterait de l’indisponibilité de l’Application.
AGAME ne saurait à cet égard être tenue pour responsable de tout dommage résultant
de la perte, de l’altération ou de toute utilisation frauduleuse de données, de la transmission
accidentelle de virus ou autres éléments nuisibles, de l’attitude ou comportement d’un tiers.
Elle n’encourt aucune responsabilité du fait (i) de l’impossibilité d’accéder à l’Application,(ii)
d’un mauvais usage de l’Application (iii) de la saturation du réseau internet, (iv) d’éventuels
dysfonctionnements sur les terminaux mobiles utilisés par vous, (v) en cas de force majeure
ou de fait indépendant de sa volonté.

ARTICLE 9 - NON RENONCIATION

Le fait pour l'une des parties de ne pas se prévaloir d'un manquement par l'autre partie à l'une
quelconque des obligations visées dans les présentes CGU ne saurait être interprété pour
l'avenir comme une renonciation à l'obligation en cause.

ARTICLE 10 - DROIT APPLICABLE -LITIGES

Les présentes CGU sont soumises au droit français.
Tout litige concernant l'Application ou l’interprétation des présentes CGU sera soumis au
tribunal compétent.

ARTICLE 11 - CONTACT

Vous pouvez adresser vos questions ou remarques relatives aux présentes CGU à AGAME en écrivant à l'adresse suivante : hello@agame.shop

Conditions rédigées et entrées en vigeur le 9 Février 2021.`;

const enTerms: string = `
These terms and conditions ("Agreement") set forth the general terms and conditions of your use of the "agame" mobile application ("Mobile Application" or "Service") and any of its related products and services (collectively, "Services"). This Agreement is legally binding between you ("User", "you" or "your") and this Mobile Application developer ("Operator", "we", "us" or "our"). By accessing and using the Mobile Application and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Mobile Application and Services. You acknowledge that this Agreement is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Mobile Application and Services.

Accounts and membership

You must be at least 13 years of age to use the Mobile Application and Services. By using the Mobile Application and Services and by agreeing to this Agreement you warrant and represent that you are at least 13 years of age. If you create an account in the Mobile Application, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.

Links to other resources

Although the Mobile Application and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement with any linked resource, unless specifically stated herein. Some of the links in the Mobile Application may be "affiliate links". This means if you click on the link and purchase an item, the Operator will receive an affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link in the Mobile Application and Services. Your linking to any other off-site resources is at your own risk.

Prohibited uses

In addition to other terms as set forth in the Agreement, you are prohibited from using the Mobile Application and Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Mobile Application and Services, third party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Mobile Application and Services, third party products and services, or the Internet. We reserve the right to terminate your use of the Mobile Application and Services for violating any of the prohibited uses.

Availability

The Application is accessible online 7 days a week and 24 hours a day. However, taking into account the complexity of the Internet network and the influx, at certain times, AGAME does not offer any guarantee of continuity of service and will not be able to its liability incurred in the event of temporary inability to access all or part of the Application. No right to compensation is granted under this paragraph.
AGAME reserves the right to cease, without notice, without compensation and in full discretion, either definitively or provisionally, to provide all or part of the Service or application features.

Indemnification

You agree to indemnify and hold the Operator and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Mobile Application and Services or any willful misconduct on your part.

Changes and amendments

We reserve the right to modify this Agreement or its terms relating to the Mobile Application and Services at any time, effective upon posting of an updated version of this Agreement in the Mobile Application. When we do, we will post a notification in the Mobile Application. Continued use of the Mobile Application and Services after any such changes shall constitute your consent to such changes. Policy was created with WebsitePolicies.

Acceptance of these terms

You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Mobile Application and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Mobile Application and Services.

Contacting us

If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to hello@agame.shop

This document was last updated on February 9, 2021`


function TermsOfUse() {

    let { lang }: { lang: string } = useParams();

    console.log(lang);

    const fr = (): JSX.Element => {
        return (
            <div style={{whiteSpace: 'pre-wrap', textAlign: 'justify'}}>
                <h1>Conditions d'utilisation AGAME</h1>
                <br/>
                <div>{frTerms}</div>
            </div>
        );
    };

    const en = (): JSX.Element => {
        return (
            <div style={{whiteSpace: 'pre-wrap', textAlign: 'justify'}}>
                <h1>AGAME : Terms of use</h1>
                <br/>
                <div>{enTerms}</div>
            </div>
        );
    };

    return (
        <div style={{padding: '2em'}}>
            {lang === 'fr' ? fr() : en()}
        </div>
    )
}

export default TermsOfUse;
