import React from 'react';
import { useTranslation } from 'react-i18next';
import './Timeline.scss';
import i18n from '../i18n';

interface TimelineItem {
    text: string;
    date: Date;
};

const content: TimelineItem[] = [
    {
        text: 'timeline.content_one',
        date: new Date('April 7, 2021'),
    },
    {
        text: 'timeline.content_two',
        date: new Date('April 15, 2021'),
    },
    {
        text: 'timeline.content_three',
        date: new Date('June 1, 2021'),
    },
];

function Timeline() {
    const { t } = useTranslation();
    const currentLanguage: string = i18n.language.includes('fr') ? 'fr-FR' : 'en-US';

    const formatLocalDate = (date: Date): string => {
        return new Intl.DateTimeFormat(currentLanguage, { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
    };

    const TimelineItem = ({ data }: { data: TimelineItem }): JSX.Element => (
        <div className="timeline-item">
            <div className="timeline-item-content">
                <time>{formatLocalDate(data.date)}</time>
                <p>{t(data.text)}</p>
                <span className="circle" />
            </div>
        </div>
    );

    return (
        <div className="timeline-container">
            {content.map((data, idx) => (
                <TimelineItem data={data} key={idx} />
            ))}
        </div>
    );
}

export default Timeline
