import React, { useCallback, useEffect, useState } from 'react';
import './Home.scss';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import Typewriter, { TypewriterClass } from 'typewriter-effect';
import i18n from '../i18n';
import detailScreenShot from '../assets/detailScreenShot.jpg';
import googleButtonEn from '../assets/google-play-en.png';
import googleButtonFr from '../assets/google-play-fr.png';
import appleButtonFr from '../assets/app-store-fr.svg';
import appleButtonEn from '../assets/app-store-en.svg';

function Home() {
    const { t } = useTranslation();
    const [lang, setLang] = useState<string>(i18n.language);

    useEffect(() => {
        setLang(i18n.language)
    }, []);

    const renderTypeWriter = useCallback(() => {
        if (lang !== i18n.language) {
            return (<Typewriter
                key={'newtyping'}
                onInit={(typewriter: TypewriterClass) => {
                    typewriter
                    .pauseFor(1500)
                    .typeString(t('home.typing_two', lang))
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(t('home.typing_one', lang))
                    .start();
                }}
            />);
        }
        return (
            <Typewriter
                key={'typing'}
                onInit={(typewriter: TypewriterClass) => {
                    typewriter
                    .pauseFor(1500)
                    .typeString(t('home.typing_two'))
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(t('home.typing_one'))
                    .start();
                }}
            />
        );
    }, [lang,t]);

    return (
        <div className="home">
            <div className="content-left">
                <div className="typing-container">
                    {renderTypeWriter()}
                </div>
                <div className="main-desc">
                    <p>{t('home.main_desc')}</p>
                </div>
                <div className="store-buttons">
                    <img
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.mougin.agame', '_blank')}
                        className="google-button"
                        alt="google play link"
                        src={i18n.language.includes('fr') ? googleButtonFr : googleButtonEn}
                    />
                    <img
                        onClick={() => window.open(i18n.language.includes('fr') ? 'https://apps.apple.com/fr/app/agame/id1563935125' : 'https://apps.apple.com/us/app/agame-video-games/id1563935125', '_blank')}
                        className="apple-button"
                        alt="App store link"
                        src={i18n.language.includes('fr') ? appleButtonFr : appleButtonEn}
                    />
                </div>
                <div>
                    <p>{t('home.author')}</p>
                </div>
            </div>
            <div className="content-right">
                <div className="flex-center phone">
                    <ReactPlayer
                        playing={true}
                        loop={true}
                        muted={true}
                        playsinline={true}
                        url={i18n.language.includes('fr') ? 'https://youtu.be/u196q6KBB6E' : 'https://youtu.be/T7-_RJPqZbE'}
                        width='100%'
                        height='90%'
                    />
                </div>
                <div className="back-phone-position">
                    <div className="phone flex-center">
                        <img className="detail-img" alt="agame logo" src={detailScreenShot} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home
