import React from 'react';
import { useTranslation } from 'react-i18next';
import './Faq.scss';

interface Subject {
    question: string;
    answer: string;
};

function Faq() {
    const { t } = useTranslation();

    const subjects: Subject[] = [
        { question: 'faq.questions.free_q', answer: 'faq.questions.free_a' },
        { question: 'faq.questions.aim_q', answer: 'faq.questions.aim_a' },
        { question: 'faq.questions.language_q', answer: 'faq.questions.language_a' },
        { question: 'faq.questions.store_q', answer: 'faq.questions.store_a' },
    ];

    return (
        <div className="faq">
            <p className="title">{t('faq.faq')}</p>
            <h1>{t('faq.title')}</h1>
            <div className="questions">
                {subjects.map((subject: Subject, index: number) => {
                    return (
                        <div key={index} className="question">
                            <h4>{t(subject.question)}</h4>
                            <p className="answer">{t(subject.answer)}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default Faq;
